import React from 'react';
import './App.css';
import { Col, Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';

export function App(): JSX.Element {
	return (
		<>
			<Outlet />
		</>
	);
}

export function Index(): JSX.Element {
	return (
		<Container className="home-jumbotron d-flex flex-column justify-content-center" fluid>
			<Container className="inner-jumbotron">
				<h1 className="display-1 fw-bold">Nathan Douglas</h1>
				<Col md={8}>
					<p className="fs-4 lead">
						Hi, I&apos;m a software developer who makes random projects from time to time depending on
						what has caught my interest at the moment. This webpage serves as a way for me to present
						these projects in a cooler way than a simple&nbsp;
						<a href="https://github.com/Unoblueboy?tab=repositories" className="text-decoration-none">
							list of git repositories
						</a>.
					</p>
				</Col>
			</Container>
		</Container>
	);
}
