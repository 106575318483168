import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App as HomePage, Index as HomeIndex } from './Components/Home/App';
import { createBrowserRouter, type RouteObject, RouterProvider } from 'react-router-dom';
import { route as projectsRoute } from './Components/Projects/route';
import { MainLayout } from './MainLayout';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

const routes: RouteObject[] = [
	{
		path: '/',
		element: <HomePage />,
		errorElement: <div>Test</div>,
		children: [
			{
				index: true,
				element: <HomeIndex />
			},
			...projectsRoute
		]
	}
];

const router = createBrowserRouter(routes);

root.render(
	<React.StrictMode>
		<MainLayout>
			<RouterProvider router={router} />
		</MainLayout>
	</React.StrictMode>
);
