import React from 'react';
import { SendEmails } from '../../../API/SecretSanta/Endpoints';

export function App(): React.ReactElement {
	const [message, setMessage] = React.useState('Click Button to Change Text');
	return (
		<div className="App">
			<h1>Hi</h1>
			<button
				onClick={() => {
					SendEmails({
						people: [
							{
								name: 'Nathan Douglas',
								email_address: 'Na'
							}
						]
					}).then((res) => {
						console.log(res);
						console.log(res.message);
						setMessage(res.message);
					})
						.finally(() => { /* Do Nothing */ });
				}}
			>Test Button</button>
			<p>{message}</p>
		</div>
	);
}

export function Preview(): React.ReactElement {
	return <div>This is a preview briefly describing the secret santa project</div>;
}

export default App;
