import React from 'react';
import { type RouteObject } from 'react-router-dom';
import { App as ProjectsPage } from './App';
import { App as SecretSantaPage } from './SecretSanta/App';

const childRoutes = [
	{
		path: 'SecretSanta',
		element: <SecretSantaPage />
	}
];

export const route: RouteObject[] = [
	{
		path: 'Projects',
		element: <ProjectsPage />
	},
	...childRoutes.map(route => { return { ...route, path: 'Projects/' + route.path }; })
];
