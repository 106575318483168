import React from 'react';
import './App.css';
import { Container, Nav } from 'react-bootstrap';
import { Preview as SecretSantaPreview } from './SecretSanta/App';

enum ProjectPathEnum {
	None = '',
	SecretSanta = 'SecretSanta',
	Project2 = 'Project2'
}

export function App(): React.ReactElement {
	const [selectedProject, setSelectedProject] = React.useState<ProjectPathEnum>(ProjectPathEnum.None);
	return (
		<div>
			<div className="sidebar">
				<Nav variant="pills" className="flex-column" onSelect={(ev) => { setSelectedProject(ProjectPathEnum[ev as keyof typeof ProjectPathEnum]); }}>
					<Nav.Link eventKey={ProjectPathEnum.SecretSanta}>Secret Santa</Nav.Link>
					<Nav.Link eventKey={ProjectPathEnum.Project2}>Project 2</Nav.Link>
				</Nav>
			</div>
			<div id="project-preview">
				<ProjectPreview selectedProject={selectedProject} />
			</div>
		</div>
	);
}

function ProjectPreview(props: { selectedProject: ProjectPathEnum }): React.ReactElement {
	const { selectedProject } = props;
	switch (selectedProject) {
		case ProjectPathEnum.None:
			return <Index/>;
		case ProjectPathEnum.SecretSanta:
			return <SecretSantaPreview/>;
		default:
			return <DefaultPreview/>;
	}
}

function Index(): React.ReactElement {
	return (
		<Container>
			<h1>Projects</h1>
			<p>
                This is a page containing all of my projects up to now. Use the tabs on the left to navigate
                the different projects.
			</p>
		</Container>
	);
}

function DefaultPreview(): React.ReactElement {
	return (
		<Container>
			<p>
            This is a preview briefly describing a default project
			</p>
		</Container>
	);
}
