import React from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';

export function MainLayout(props: { children?: React.ReactNode | undefined }): React.ReactElement {
	const { children } = props;
	return <>
		<GenerateNavbar/>
		{children}
	</>;
}

function GenerateNavbar(): React.ReactElement {
	return <Navbar bg="light" expand="lg" sticky="top">
		<Container>
			<Navbar.Brand href="/">Nathan Douglas</Navbar.Brand>
			<Navbar.Toggle aria-controls="basic-navbar-nav" />
			<Navbar.Collapse id="basic-navbar-nav">
				<Nav className="me-auto">
					<Nav.Link href="/">Home</Nav.Link>
					<Nav.Link href="/Projects">Projects</Nav.Link>
				</Nav>
			</Navbar.Collapse>
		</Container>
	</Navbar>;
}
